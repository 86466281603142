<template>
  <FormWrapper
    :formTitle="''"
    :v="$v.form"
    :formButton="translations('LoginSubmit')"
    :successMessage="
      checkoutReturning
        ? translations('YouLoggedInRedirectingCheckout')
        : translations('YouLoggedInRedirectingAccount')
    "
    :formCallback="doLogin"
    @form-success="goFurther"
  >
    <EmailField
      v-model="form.email"
      :fieldLabel="translations('EnterYourEmail')"
      :v="$v.form.email"
    />
    <PasswordField
      v-model="form.password"
      :fieldLabel="translations('EnterYourPassword')"
      :v="$v.form.password"
    />
    <div class="flex-between-center">
      <CheckboxField
        v-model="form.rememberMe"
        :inline="false"
        :fieldLabel="translations('RememberMe')"
      />
      <router-link to="/forgot-password">{{
        translations("ForgotPassword")
      }}</router-link>
    </div>
  </FormWrapper>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import FormWrapper from "./FormWrapper";
import EmailField from "./FieldComponents/EmailField";
import PasswordField from "./FieldComponents/PasswordField";
import CheckboxField from "./FieldComponents/CheckboxField";
import { email, required, minLength } from "vuelidate/lib/validators";

export default {
  name: "LoginForm",
  data: () => {
    return {
      form: {
        email: "",
        password: "",
        rememberMe: localStorage.getItem("rememberMe") ? true : false,
      },
    };
  },
  components: { FormWrapper, EmailField, PasswordField, CheckboxField },
  computed: {
    ...mapGetters(["translations"]),
    checkoutReturning: () => {
      if (localStorage.getItem('checkout_returning')) {
        return true
      }
      return false
    },
  },
  validations: {
    form: {
      email: { email, required },
      password: { required, minLength: minLength(6) },
    },
  },
  methods: {
    ...mapActions(["login"]),
    async doLogin(recaptchaToken) {
      console.log("начало логина");
      let cartIds = localStorage.getItem("cart_ids");
      if (cartIds) {
        cartIds = JSON.parse(cartIds);
      } else {
        cartIds = [];
      }
      await this.login({
        email: this.form.email,
        password: this.form.password,
        rememberMe: this.form.rememberMe ? 1 : 0,
        token: recaptchaToken,
        cartIds,
      });
    },
    goFurther() {
      setTimeout(() => {
        if (this.checkoutReturning) {
          localStorage.removeItem('checkout_returning');
          this.$router.push("/checkout");
        } else {
          this.$router.push("/account");
        }
      }, 1000);
    },
  },
};
</script>