<template>
  <div class="contents-area">
    <div class="login-container">
      <div
        class="login-container-login-pic"
        :style="
          'background-image: linear-gradient(0deg, rgba(92, 107, 171, 0.3), rgba(92, 107, 171, 0.3)), url(//' +
          serverLink +
          '/upload/image/login-img.jpg); background-position: ' +
          picPosition
        "
      ></div>
      <div class="login-container-form-area">
        <div class="login-register-cont" v-if="isSettingsLoaded">
          <div class="login-menu">
            <a
              ref="loginLink"
              href="#"
              :class="{ active: workingType === 'login' }"
              @click.prevent="setWorkingType('login', $event)"
              >{{ translations("Login") }}</a
            >
            <a
              ref="registerLink"
              href="#"
              :class="{ active: workingType === 'register' }"
              @click.prevent="setWorkingType('register', $event)"
              >{{ translations("SignIn") }}</a
            >
            <div
              class="underline"
              :style="
                'left:' +
                underlinePosition.x +
                'px; width:' +
                underlinePosition.width +
                'px;'
              "
            ></div>
          </div>
          <div class="forms-container">
            <transition name="fade" mode="out-in">
              <div
                class="login-part"
                v-if="workingType === 'login'"
                key="login"
              >
                <LoginForm />
              </div>
              <div
                class="register-part"
                v-if="workingType === 'register'"
                key="register"
              >
                <RegisterForm />
              </div>
            </transition>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { VUE_APP_BACK_URL } from "@/config";
import { mapGetters, mapActions } from "vuex";
import { email, required, minLength, sameAs } from "vuelidate/lib/validators";
import LoginForm from "@/components/app/forms/LoginForm";
import RegisterForm from "@/components/app/forms/RegisterForm";

export default {
  name: "Login",
  data: () => {
    return {
      serverLink: VUE_APP_BACK_URL,
      workingType: "login",
      loginForm: {
        email: "",
        password: "",
        rememberMe: localStorage.getItem("rememberMe"),
      },
      registerForm: {
        email: "",
        password: "",
        repeatPassword: "",
      },
      underlinePosition: { x: 0, width: 100 },
    };
  },
  validations: {
    loginForm: {
      email: { email, required },
      password: { required, minLength: minLength(6) },
    },
    registerForm: {
      email: { email, required },
      password: { required, minLength: minLength(6) },
      repeatPassword: { sameAsPassword: sameAs("password") },
    },
  },
  metaInfo() {
    return {
      title: this.translations("LoginPage"),
    };
  },
  components: { LoginForm, RegisterForm },
  computed: {
    ...mapGetters(["translations", "isSettingsLoaded"]),
    picPosition() {
      return !this.workingType || this.workingType === "login"
        ? "left center"
        : "right center";
    },
  },
  methods: {
    ...mapActions(["login", "register"]),
    setWorkingType(type) {
      this.workingType = type;
      let el =
        this.workingType === "login"
          ? this.$refs.loginLink
          : this.$refs.registerLink;
      this.underlinePosition = {
        x: el.offsetLeft,
        width: el.clientWidth,
      };
    },
  },
  mounted() {
    setTimeout(() => {
      this.workingType = "login";
      this.underlinePosition = {
        x: this.$refs.loginLink.offsetLeft,
        width: this.$refs.loginLink.clientWidth,
      };
    }, 500);
  },
};
</script>