<template>
  <div
    :class="[
      'form-field password-field',
      { hasValue: password },
      { invalid: v.$error },
      { valid: !v.$error && v.$dirty },
      { open: passwordOpen },
    ]"
  >
    <input :type="passwordType" autocomplete="off" v-model="password" />
    <label
      >{{ fieldLabel }}
      <span v-if="v.$params['required']" class="required">*</span>
    </label>
    <small :class="{ opened: errorMessage }" class="error-label">{{
      errorMessage
    }}</small>
    <span class="format" @click="togglePasswordOpen"></span>
  </div>
</template>

<script>
import { getValidationErrorMessage, validationDelay } from "@/config";
import { mapGetters } from "vuex";

const touchMap = new WeakMap();

export default {
  name: "PasswordField",
  data: () => {
    return {
      passwordType: "password",
    };
  },
  props: {
    fieldLabel: {
      type: String,
      required: true,
    },
    fieldClosed: {
      type: Boolean,
      default: true,
    },
    value: {
      type: String,
      default: "",
    },
    v: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters(["translations"]),
    password: {
      get() {
        return this.value;
      },
      set(value) {
        if (touchMap.has(this.v)) {
          clearTimeout(touchMap.get(this.v));
        }
        touchMap.set(
          this.v,
          setTimeout(() => {
            this.v.$reset();
            this.v.$touch();
          }, validationDelay)
        );

        this.$emit("input", value);
        this.$emit("input", value);
      },
    },
    passwordOpen() {
      return this.passwordType !== "password";
    },
    errorMessage() {
      if (this.v.$error) {
        let msg = getValidationErrorMessage(this.v);
        return this.translations(msg.message, msg.params || []);
      }
      return "";
    },
  },
  methods: {
    togglePasswordOpen() {
      if (this.passwordType === "password") {
        this.passwordType = "text";
      } else {
        this.passwordType = "password";
      }
    },
  },
  mounted() {
    this.passwordType = this.fieldClosed ? "password" : "text"
  },
};
</script>
