<template>
  <FormWrapper
    :formTitle="''"
    :v="$v.form"
    :formButton="translations('RegisterSubmit')"
    :successMessage="translations('YouRegisteredRedirectingAccount')"
    :formCallback="doRegister"
    @form-success="goFurther"
  >
    <EmailField
      v-model="form.email"
      :fieldLabel="translations('EnterYourEmail')"
      :v="$v.form.email"
    />
    <PasswordField
      v-model="form.password"
      :fieldLabel="translations('EnterYourPassword')"
      :v="$v.form.password"
    />
    <PasswordField
      v-model="form.repeatPassword"
      :fieldLabel="translations('RepeatYourPassword')"
      :v="$v.form.repeatPassword"
    />
    <template v-slot:privacy>
      <p
        class="grey privacy"
        v-html="
          translations('AgreeToPrivacyAndOferta', [pricvacyText, ofertaText])
        "
      ></p>
    </template>
  </FormWrapper>
</template>

<script>
import { VUE_APP_BACK_URL } from "@/config";
import { mapGetters, mapActions } from "vuex";
import FormWrapper from "./FormWrapper";
import EmailField from "./FieldComponents/EmailField";
import PasswordField from "./FieldComponents/PasswordField";
//import RepeatPasswordField from "./FieldComponents/RepeatPasswordField";
import { email, required, minLength, maxLength, sameAs } from "vuelidate/lib/validators";

export default {
  name: "ForgotPasswordForm",
  components: { FormWrapper, EmailField, PasswordField },
  data: () => {
    return {
      serverLink: VUE_APP_BACK_URL,
      ofertaText: "",
      pricvacyText: "",
      form: {
        email: "",
        password: "",
        repeatPassword: "",
      },
    };
  },
  validations: {
    form: {
      email: { email, required },
      password: { required, minLength: minLength(6), maxLength: maxLength(30) },
      repeatPassword: { sameAsPassword: sameAs("password") },
    },
  },
  computed: {
    ...mapGetters(["translations", "listSettings"]),
  },
  methods: {
    ...mapActions(["register"]),
    async doRegister(recaptchaToken) {
      await this.register({
        email: this.form.email,
        password: this.form.password,
        repeatPassword: this.form.repeatPassword,
        token: recaptchaToken,
        lang: this.$cookies.get("user_language"),
      });
    },
    goFurther() {
      setTimeout(() => {
        this.$router.push("/account");
      }, 1000);
    },
  },
  mounted() {
    this.ofertaText =
      '<a href="//' +
      this.serverLink +
      this.listSettings.dogovor_oferta +
      '" target="_blank">' +
      this.translations("OfertaAgreementRod") +
      "</a>";
    this.pricvacyText =
      '<a href="/page/privacy-policy" target="_blank">' +
      this.translations("PrivacyAgreementRod") +
      "</a>";
  },
};
</script>