<template>
  <div
    :class="['form-field', { invalid: fieldInvalid }, { valid: !fieldInvalid }]"
  >
    <div
      :class="[
        'checkbox',
        { invalid: fieldInvalid },
        { inline: $props.inline },
        { valid: !fieldInvalid },
      ]"
    >
      <div class="checkbox-inner">
        <input :id="id" type="checkbox" v-model="checked" />
        <i></i>
      </div>
      <label :for="id">{{ fieldLabel }}</label>
    </div>
    <small :class="{ opened: errorMessage }" class="error-label">{{
      errorMessage
    }}</small>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "EmailField",
  data() {
    return {
      id: null,
    };
  },
  props: {
    fieldLabel: {
      type: String,
      default: "",
    },
    inline: {
      type: Boolean,
      default: true, // can be empty for <display: block>
    },
    value: {
      type: Boolean,
      default: false,
    },
    v: {
      type: Object,
      required: false,
    },
  },
  computed: {
    ...mapGetters(["translations"]),
    checked: {
      get() {
        return this.value;
      },
      set(value) {
        console.log(this.v);
        if (this.v) {
          this.v.$touch();
        }
        this.$emit("input", value);
      },
    },
    errorMessage() {
      if (this.v && this.v.$dirty && this.v.$error) {
        return this.translations("FieldMustBeChecked");
      }
      return "";
    },
    fieldInvalid() {
      return this.v && this.v.$dirty && this.v.$error;
    },
  },
  mounted() {
    this.id = this._uid;
  },
};
</script>
