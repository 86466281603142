var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"contents-area"},[_c('div',{staticClass:"login-container"},[_c('div',{staticClass:"login-container-login-pic",style:('background-image: linear-gradient(0deg, rgba(92, 107, 171, 0.3), rgba(92, 107, 171, 0.3)), url(//' +
        _vm.serverLink +
        '/upload/image/login-img.jpg); background-position: ' +
        _vm.picPosition)}),_c('div',{staticClass:"login-container-form-area"},[(_vm.isSettingsLoaded)?_c('div',{staticClass:"login-register-cont"},[_c('div',{staticClass:"login-menu"},[_c('a',{ref:"loginLink",class:{ active: _vm.workingType === 'login' },attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.setWorkingType('login', $event)}}},[_vm._v(_vm._s(_vm.translations("Login")))]),_c('a',{ref:"registerLink",class:{ active: _vm.workingType === 'register' },attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.setWorkingType('register', $event)}}},[_vm._v(_vm._s(_vm.translations("SignIn")))]),_c('div',{staticClass:"underline",style:('left:' +
              _vm.underlinePosition.x +
              'px; width:' +
              _vm.underlinePosition.width +
              'px;')})]),_c('div',{staticClass:"forms-container"},[_c('transition',{attrs:{"name":"fade","mode":"out-in"}},[(_vm.workingType === 'login')?_c('div',{key:"login",staticClass:"login-part"},[_c('LoginForm')],1):_vm._e(),(_vm.workingType === 'register')?_c('div',{key:"register",staticClass:"register-part"},[_c('RegisterForm')],1):_vm._e()])],1)]):_vm._e()])])])}
var staticRenderFns = []

export { render, staticRenderFns }